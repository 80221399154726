import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../SGRE-shared/services/global.service';
@Injectable({
  providedIn: 'root',
})
export class OrderDetailsService {
  private orderdetailssubject = new BehaviorSubject<any>(null);
  orderdetails$ = this.orderdetailssubject.asObservable();

  orderid: any;

  private orderdetailarraySubject = new BehaviorSubject<any[]>([]);
  currentData = this.orderdetailarraySubject.asObservable();

  private cancelSubject = new BehaviorSubject<boolean>(false);
  canceldata$ = this.cancelSubject.asObservable();

  public OrderdetailListSubject = new BehaviorSubject<any>({});
  public OrderListData$ = this.OrderdetailListSubject.asObservable();

  private isQuotationSource = new BehaviorSubject<boolean>(
    this.getIsQuotationFromStorage()
  );
  public isQuotation$ = this.isQuotationSource.asObservable();
  public orderErrorFlag: boolean = false;
  constructor(
    private readonly apiService: ApiService,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) {}

  getOrderDetails() {
    this.globalService.loadingSubject.next(true);
    let paramsObject = { fields: 'FULL' };
    this.route.queryParamMap.subscribe((x: any) => {
      this.orderid = x['params'];
    });
    this.apiService
      .getOrderDetailList(paramsObject, this.orderid.orderId)
      .pipe(
        map((data: any) => {
          let dataobj = data;
          if (data.status == 'A') {
            data.status = 'Not Yet Processed';
          }
          if (data.status == 'B') {
            data.status = 'Partially Processed';
          }
          if (data.status == 'C') {
            data.status = 'Completely processed';
          }
          if (data.status == 'PENDING_APPROVAL') {
            data.status = 'PENDING APPROVAL';
          }
          data.legalEntity?.name == data.legalEntity?.name?.toLowerCase();
          let dateSent = new Date(data.date);
          let now = new Date();
          let utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
          var datasentt = new Date(
            dateSent.getTime() + dateSent.getTimezoneOffset() * 60000
          );
          let date = Math.floor(
            (utc.getTime() - new Date(datasentt).getTime()) / 1000 / 60
          );
          let dateformated = date;
          data['datevalue'] = dateformated;
          let results = data?.entries?.map((item) =>
            this.mapOrderDetailsData(item, dataobj)
          );
          data['results'] = results;
          return data;
        }),
        catchError((err) => {
          this.globalService.loadingSubject.next(false);
          return throwError(() => err);
        })
      )
      .subscribe({
        next: (data) => {
          this.OrderdetailListSubject.next(data);
          this.orderErrorFlag = false;
        },
        error: (error) => {
          this.orderErrorFlag = true;
        },
      });
  }

  mapOrderDetailsData(orderDetailsData, dataobj) {
    orderDetailsData['image'] = orderDetailsData?.product?.images?.at(0)?.url
      ? environment.siteUrls.getBackofficeUrl.slice(0, -1) +
        orderDetailsData?.product?.images?.at(0)?.url
      : '../../../../assets/images_and_icons/image1.jpg';
    orderDetailsData['partnumber'] = orderDetailsData?.product?.baseProduct;
    orderDetailsData['productname'] = orderDetailsData?.product?.name;
    orderDetailsData['varianttype'] =
      orderDetailsData?.product?.baseOptions?.at(0)?.selected.refurbished
        ? 'R'
        : 'N';
    orderDetailsData['updatedeta'] = orderDetailsData?.originalEta?.slice(
      0,
      10
    );
    orderDetailsData['etaFlag'] = false;
    orderDetailsData['etaColumn'] = [];
    orderDetailsData['orderDetailEta'] =
      orderDetailsData?.eta?.slice(0, 10) !=
      orderDetailsData?.originalEta?.slice(0, 10)
        ? orderDetailsData?.eta?.slice(0, 10)
        : '';
    orderDetailsData['leadTime'] = orderDetailsData?.leadtime;
    orderDetailsData['currency'] = !orderDetailsData?.isQuotation
      ? orderDetailsData?.basePrice?.currencyIso
      : '-';
    orderDetailsData['unit'] = !orderDetailsData?.isQuotation
      ? orderDetailsData?.basePrice?.value
      : '-';
    orderDetailsData['quantity'] = orderDetailsData?.quantity;
    orderDetailsData['totalcurrency'] = !orderDetailsData?.isQuotation
      ? orderDetailsData?.totalPrice?.currencyIso
      : '-';
    orderDetailsData['total'] = !orderDetailsData?.isQuotation
      ? orderDetailsData?.totalPrice?.value
      : '-';
    orderDetailsData['comment'] = orderDetailsData?.itemCancelComment != undefined
      ? orderDetailsData?.itemCancelComment
      : '';
    orderDetailsData['deleteoption'] =
      (dataobj.status == 'Not Yet Processed' &&
        dataobj?.deliveryMode?.name == 'Turbine down') ||
      (dataobj.status == 'Not Yet Processed' && dataobj.datevalue > 1440) ||
      orderDetailsData.cancelled == true
        ? true
        : false;
    orderDetailsData['strike'] =
      orderDetailsData.cancelled == true ? true : false;
    orderDetailsData['orginalEtastrike'] =
      dataobj.status == 'CANCELLED' || orderDetailsData.cancelled == true ? true : false;
    orderDetailsData['Etastrike'] =
      dataobj.status == 'CANCELLED' || orderDetailsData.cancelled == true ? true : false;
    dataobj.status == 'CANCELLED' ? true : false;
    if (
      orderDetailsData?.eta?.slice(0, 10) !=
      orderDetailsData?.originalEta?.slice(0, 10)
    ) {
      orderDetailsData.etaFlag = true;
      orderDetailsData.etaColumn.push('updatedeta');
      orderDetailsData.Etastrike = true;
    }
    if (orderDetailsData.cancelled == true) {
      dataobj['itemCancel'] = true;
    }
    return orderDetailsData;
  }

  setOrderdetailsObject(orderdetails: any) {
    this.OrderdetailListSubject.next(orderdetails);
  }

  setIsQuotation(value: boolean) {
    this.isQuotationSource.next(value);
    this.setIsQuotationToStorage(value);
  }
  removeIsQuotation(value: boolean) {
    this.isQuotationSource.next(value);
    this.removeIsQuotationFromStorage(value);
  }

  public deleteOrderDetails(entryNumber, comment): Observable<any> {
    let commentStr: string = comment;
    let paramsObject = { fields: 'FULL' };
    return this.apiService.deleteorderdetail(
      this.orderid.orderId,
      entryNumber,
      paramsObject,
      commentStr
    );
  }

  cancelupdate(data: boolean) {
    this.cancelSubject.next(data);
  }

  deleteWholeOrder(comment) {
    let paramsObject = { fields: 'FULL' };
    return this.apiService.deleteWholeOrder(
      this.orderid.orderId,
      paramsObject,
      comment
    );
  }

  recreatecart() {
    let paramsObject = { fields: 'FULL' };
    return this.apiService.recreate(this.orderid.orderId, paramsObject);
  }
  private setIsQuotationToStorage(value: boolean) {
    localStorage.setItem('isQuotation', JSON.stringify(value));
  }
  private removeIsQuotationFromStorage(value: boolean) {
    localStorage.removeItem('isQuotation');
  }
  private getIsQuotationFromStorage(): boolean {
    const storedValue = localStorage.getItem('isQuotation');
    return storedValue ? JSON.parse(storedValue) : false;
  }
  
  removeData()
  {
    this.OrderdetailListSubject.next([]);
  }
}
